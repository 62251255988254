@tailwind base;
@tailwind components;
@tailwind utilities;
@import "react-slideshow-image/dist/styles.css";


*,
*:before,
*:after {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  min-height: 100%;
}
body {
  font-family: "times new roman";
  position: relative;
  overflow: hidden;
}

@font-face {
  font-family: 'Distant Stroke';
  src: local('Distant Stroke'), url(./fonts/Distant_Stroke_Medium.otf) format('opentype');
}

#root {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
}
a {
  cursor: pointer;
}
/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: "";
  @apply tw-absolute tw-w-6 tw-h-6 tw-top-0 tw-bottom-0 tw-left-0 tw-border tw-bg-white tw-rounded-lg tw-mb-auto tw-mt-auto;
}
/* checked mark aspect */
[type="checkbox"]:checked + label:after {
  content: "✕";
  @apply tw-flex tw-absolute tw-items-center tw-w-6 tw-h-6 tw-px-1 tw-text-black tw-top-0 tw-bottom-0 tw-left-0 tw-mb-auto tw-mt-auto tw-font-bold tw-justify-center;
}

/*#region Animations*/
.slideFromTop {
  animation: slideFromTop 2.6s;
  -moz-animation: slideFromTop 2.6s; /* Firefox */
  -webkit-animation: slideFromTop 2.6s; /* Safari and Chrome */
  -o-animation: slideFromTop 2.6s; /* Opera */
}
@keyframes slideFromTop {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

@-webkit-keyframes slideFromTop {
  /* Safari and Chrome */
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}
@-o-keyframes slideFromTop {
  /* Opera */
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}
/*#endregion Animations*/